
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  MultiCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    MultiCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 4;
    const router = useRouter();

    const title = "Do any of the following apply to you?";
    const options = [
      {
        label: "I am/could be pregnant",
        value: "I am/could be pregnant",
      },
      {
        label: "I am breastfeeding",
        value: "I am breastfeeding",
      },
      {
        label: "I had a child in the last 6 weeks",
        value: "I had a child in the last 6 weeks",
      },
      {
        label: "None of the Above",
        value: "NIL",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      title,
      questionNumber,
    };
  },
});
